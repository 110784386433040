<template>
    <div class="row">
        <div class="col-12" v-if="!isLoad">
            <div v-if="exist" class="row">
                <div class="col-12" v-if="question != null">
                    <div v-if="start" class="card d-block w-100 shadow-0 bg-transparent rounded-lg p-3 border-0 text-left">
                        <div class="card-body p-4 bg-white rounded-lg">
                            <div class="row justify-content-between px-2">
                                <div class="col-md-9">
                                    <button v-for="(item, i) in question" :key="i" @click="current = i" :class="{'btn-current text-white' : current == i || submission.answer[i] != null, 'btn-outline-current text-current' : current != i}" class="btn w50 h50 col mr-2 mb-2" :disabled="!change">{{i + 1}}</button>
                                </div>
                                <div class="col-md-3">
                                    <div class="float-right timer pt-2 px-1 pb-0 rounded-lg bg-current">
                                        <div class="time-count" v-if="time >= 3600">
                                            <span class="text-time text-current">{{ formatedCountdown.split(":")[0] }}</span>
                                            <span class="text-day text-white">Hours</span>
                                        </div>
                                        <div class="time-count" v-else>
                                            <span class="text-time text-current">00</span>
                                            <span class="text-day text-white">Hours</span>
                                        </div>
                                        <div class="time-count" v-if="time >= 3600">
                                            <span class="text-time text-current">{{ formatedCountdown.split(":")[1] }}</span>
                                            <span class="text-day text-white">Min</span>
                                        </div>
                                        <div class="time-count" v-else-if="time < 3600 && time >= 60">
                                            <span class="text-time text-current">{{ formatedCountdown.split(":")[0] }}</span>
                                            <span class="text-day text-white">Min</span>
                                        </div>
                                        <div class="time-count" v-else>
                                            <span class="text-time text-current">00</span>
                                            <span class="text-day text-white">Min</span>
                                        </div>
                                        <div class="time-count" v-if="time >= 3600">
                                            <span class="text-time text-current">{{ formatedCountdown.split(":")[2] }}</span>
                                            <span class="text-day text-white">Sec</span>
                                        </div> 
                                        <div class="time-count"  v-else-if="time < 3600 && time >= 60">
                                            <span class="text-time text-current">{{ formatedCountdown.split(":")[1] }}</span>
                                            <span class="text-day text-white">Sec</span>
                                        </div> 
                                        <div class="time-count"  v-else-if="time < 60 && time >= 0">
                                            <span class="text-time text-current">{{ formatedCountdown.split(":")[0] }}</span>
                                            <span class="text-day text-white">Sec</span>
                                        </div> 
                                        <div class="time-count"  v-else>
                                            <span class="text-time text-current">00</span>
                                            <span class="text-day text-white">Sec</span>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form v-if="start" @submit.prevent="submitAnswer" class="card mb-5 d-block w-100 shadow-0 bg-transparent rounded-lg p-3 border-0 text-left question-div">
                        <div v-for="(item, i) in question" :key="i" class="card-body p-4 bg-white rounded-lg mb-3" v-show="current == i">
                            <div class="bg-lightblue py-2 theme-dark-bg text-center" v-if="item.type == 1">
                                <video class="w-50 h-auto" controls>
                                    <source :src=" item.path" type="video/mp4">
                                </video>
                            </div>
                            <div style="min-height:25px !important" class="ql-editor mb-3 bg-lightblue p-3 theme-dark-bg">
                            <div class="bg-lightblue py-2 theme-dark-bg" v-if="item.type == 2">
                                <div class="row">
                                    <div class="col-6">
                                        <button v-if="played[i] == true" type="button" disabled class="btn btn-lg btn-danger rounded-circle w50 h50 mr-2"><i class="fas fa-stop"></i></button>
                                        <a v-else href="javascript:void(0)" @click="startAudio(i)" class="btn btn-lg btn-current rounded-circle w50 h50 mr-2"><i class="fas fa-play"></i></a>
                                        <strong class="text-danger">Your online can play this audio once!</strong>
                                    </div>
                                    <div class="col">
                                        <audio :ref="setItemRef(i)" class="w-50" controlsList="nodownload" muted="true">
                                            <source :src=" item.path" type="audio/mpeg">
                                        </audio>
                                    </div>
                                </div>
                            </div>
                                <div class="ql-editor">
                                    <div v-html="item.question"></div>
                                </div>
                            </div>
                            <div v-if="item.submission_type == 1">
                                <div v-for="(opt, j) in item.option" :key="j" class="bg-lightgrey theme-dark-bg py-3 px-2 mt-3 style2 rounded-lg font-xssss fw-600 lh-28 text-grey-700 mb-0 p-2">
                                    <span class="py-2 px-3 mr-2 d-inline-block rounded-circle text-white font-xssss fw-600 radio-cont">
                                        <input v-model="submission.answer[i]" type="radio" :value="opt.id" :name="`radios[${i}]`">
                                    </span>
                                    <div class="d-inline-block">
                                        {{opt.option}}
                                    </div>
                                </div>
                            </div>
                            <div v-if="item.submission_type == 2">
                                <div v-for="(opt, j) in item.option" :key="j" class="bg-lightgrey theme-dark-bg py-3 px-2 mt-3 style2 rounded-lg font-xssss fw-600 lh-28 text-grey-700 mb-0 p-2">
                                    <span class="py-2 px-3 mr-2 d-inline-block rounded-circle text-white font-xssss fw-600 radio-cont">
                                        <input @click="setAnswerCheck(i)" type="checkbox" :value="opt.id" :id="`check${i}`" :ref="`check${i}`" :name="`check${i}`">
                                    </span>
                                    <div class="d-inline-block">
                                        {{opt.option}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-3 bg-white rounded-lg mb-5">
                            <div class="row">
                                <div class="col-6">
                                    <button v-if="current != 0" @click="current -= 1" class="btn btn-lg btn-outline-primary fw-500" type="button">Prev</button>
                                </div>
                                <div class="col-6 text-right">
                                    <button v-if="current != question.length-1" @click="current += 1" class="btn btn-lg btn-primary fw-500 ml-2" type="button">Next</button>
                                    <button v-if="current == question.length-1" type="submit" class="btn btn-lg btn-current fw-500 ml-3" :disabled="process || submission.answer.filter(x => x == null).length != 0">
                                        <div v-if="process" class="spinner-border text-white" style="width: 1.5rem; height: 1.5rem;" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        {{ process ? '' : 'Submit' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-12" v-else>
                    <div class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row">
                            <div class="col-12 text-center py-3">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8 text-center default-page">
                            <div class="card border-0 text-center d-block">
                                <div class="card-body">
                                    <img :src="'/images/404.png'" alt="icon" class="w300 mb-4 ml-auto mr-auto ">
                                    <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Page Not Found.</h1>
                                    <p class="text-grey-500 font-xss">The page you're looking for isn't available. Try to search again or use the go to.</p>
                                    <router-link :to="{name:'GettingStartedPT', params:{idPT: idPT}}" class="p-3 border-0 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Return Back</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" v-else>
            <div class="row">
                <div class="col-12 py-3 text-center">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import "moment-duration-format";
export default {
    data(){
        return{
            time: 0,
            process: false,
            change: true,
            paramsId: this.$route.params.idCourse,
            idPT: this.$route.params.idPT,
            media : process.env.VUE_APP_URL_CLOUD,
            exist: true,
            start : false,
            isLoad: true,
            interval: null,
            current: 0,
            question: [],
            index:-1,
            files:[],
            played:[],
            upload:{
                path:[]
            },
            submission:{
                id:[],
                answer:[]
            }
        }
    },
    computed: {
        formatedCountdown() {
            return moment.duration(this.time, "seconds").format("hh:mm:ss");
        },
    },
    created(){
        this.getPT()
    },
    beforeDestroy() {
        if(this.interval != null) {
            clearInterval(this.interval)
        }
    },
    methods: {
        setAnswerCheck(index){
          var temp = this.$refs[`check${index}`];
          var ans = [];
          temp.map(el => {
            if(el.checked) {
              ans.push(el.value)
            }
          })
          this.submission.answer[index] = ans.join(',')
        },
        startAudio(index){
            this.played[index] = true,
            this.$refs[`audio${index}`][0].play()
        },
        startTest(){
            this.$swal({
                title: 'Diagnostic Test!',
                text: 'Click button bellow to start test!',
                icon: 'info',
                confirmButtonText:'<i class="fa fa-clock mr-2"></i> Start!',
                showConfirmButton: true,
                allowOutsideClick: false,
                preConfirm: () => {
                    this.start = true
                    this.interval = setInterval(() => {
                        this.time -= 1;
                        if (this.time == 180){
                            this.$swal({
                                toast: true,
                                title: 'Diagnostic Test!',
                                text: '3 minute left!',
                                icon: 'info',
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                        }
                        if (this.time == 60){
                            this.$swal({
                                toast: true,
                                title: 'Diagnostic Test!',
                                text: 'A minute left!',
                                icon: 'info',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            })
                        }
                        if (!this.time) {
                            clearInterval(this.interval);
                            this.postAnswer()
                            this.$swal({
                                title: 'Diagnostic Test!',
                                text: 'Time is up!',
                                icon: 'warning',
                                showConfirmButton: false,
                                allowOutsideClick: false,
                            })    
                        } 
                    }, 1000); 
                },
            })  
        },
        setItemRef(i) {
            return `audio${i}`;
        },
        async submitAnswer(){
            this.$swal({
                title: 'Are you sure to submit this test?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Submit it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
            }).then(async(result) => {
                if(result.value) {
                    await this.postAnswer()
                }
            })
        },
        async postAnswer(){
            this.process = true
            const submission = this.question.map((item, i) => {
                return {
                    id: this.submission.id[i] ? this.submission.id[i] : null,
                    quest_id: item.id,
                    type: item.submission_type,
                    answer: this.submission.answer[i] ? this.submission.answer[i] : null,
                    corect: this.submission.answer[i] == item.answer ? 1 : 0 ,
                };
            })
            var data = {
                pt_id: this.idPT,
                submission: submission,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/core/getting-start/pt/submit`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.$router.push({ name: 'GettingStartedPT', params:{idPT: this.idPT} })
                    this.$swal({
                        toast: true,
                        title: 'Diagnostic Test!',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Diagnostic Test!',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).finally(() => this.process = false)
        },
        async getPT(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/getting-start/pt/quest?id=${this.idPT}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success) {
                    if(res.data.pt.own_submission == null) {
                        this.time = res.data.pt.time * 60
                        this.question = res.data.data
                        this.question.map((item, index) => {
                            if(item.ans != null) {
                                this.submission.id[index] = item.ans.id
                                this.submission.answer[index] = item.ans.answer
                            } else {
                                this.submission.id[index] = null
                                this.submission.answer[index] = null
                            }
                                
                        })
                        this.isLoad = false
                        this.startTest()
                    } else {
                        this.$swal({
                            toast: true,
                            title: 'Diagnostic Test!',
                            text: 'You already finished the test',
                            icon: 'info',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 1000);
                    }
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Diagnostic Test!',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(err => {
                if (err.response.status == 404) {
                    this.isLoad = false
                    this.exist = false
                }
            })
        },
    }
}
</script>
<style scoped>
    .radio-cont input[type='radio'] {
        transform: scale(2.2);
        visibility: hidden;
    }
    .radio-cont input[type='radio']:after {
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: 50%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #d1d3d1;
        content: '';
        visibility: visible;
        border: none;
    }

    .radio-cont input[type='radio']:checked:after {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #ffa500;
        content: '';
        display: inline-block;
        visibility: visible;
        border: none;
    }

    .radio-cont input[type='checkbox'] {
        transform: scale(2.2);
        visibility: hidden;
    }
    .radio-cont input[type='checkbox']:after {
        width: 100%;
        height: 100%;
        cursor: pointer;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #d1d3d1;
        content: '';
        visibility: visible;
        border: none;
    }

    .radio-cont input[type='checkbox']:checked:after {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #ffa500;
        content: '';
        display: inline-block;
        visibility: visible;
        border: none;
    }
    ::v-deep .ql-editor img {
        width: 100% !important;
    }
</style>


